<template>
  <v-card :loading="isLoading">
    <v-toolbar>
      <v-toolbar-title>
        {{ `${$i18n.translate("Promotion")} - ${form.name}` }}
        {{
          selectedPromotionType && selectedPromotionType.inputType
            ? `(${selectedPromotionType.inputType.description})`
            : ""
        }}
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip bottom v-if="!isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onCopyConfirm" v-bind="attrs" v-on="on">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Copy") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!isValid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>
      <template v-slot:extension>
        <v-tabs v-model="tab" v-if="!isLoading">
          <v-tab>
            <v-badge v-if="!hasRequiredDetailsFields" icon="mdi-exclamation-thick" color="error">
              <span>{{ $i18n.translate("Details") }}</span>
            </v-badge>
            <span v-else>{{ $i18n.translate("Details") }}</span>
          </v-tab>
          <v-tab>{{ $i18n.translate("Deadlines") }}</v-tab>
          <v-tab>{{ $i18n.translate("Audience") }}</v-tab>
          <v-tab>{{ $i18n.translate("Products") }}</v-tab>
          <v-tab
            v-if="
              selectedPromotionType &&
                selectedPromotionType.inputType &&
                selectedPromotionType.inputType.name == 'CLAIM'
            "
          >
            <v-badge v-if="!selectedClaimType" icon="mdi-exclamation-thick" color="error">
              <span>{{ $i18n.translate("Claiming") }}</span>
            </v-badge>
            <span v-else>{{ $i18n.translate("Claiming") }}</span>
          </v-tab>
          <v-tab>{{ $i18n.translate("Contest") }}</v-tab>
          <v-tab>{{ $i18n.translate("Documents") }}</v-tab>
          <v-tab>{{ $i18n.translate("Custom Fields") }}</v-tab>
          <v-tab v-if="!isNew">{{ $i18n.translate("Timeline") }}</v-tab>
          <v-tab v-if="!isNew">{{ $i18n.translate("Limits") }}</v-tab>
          <v-tab v-if="!isNew">{{ $i18n.translate("Rules") }}</v-tab>
          <v-tab v-if="!isNew">{{ $i18n.translate("Courses") }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors" />
      <div class="text-center" v-if="isLoading">
        <v-progress-circular indeterminate color="primary" />
      </div>
      <v-form :value="isValid" @submit.prevent="onSubmit" v-else v-model="isValid">
        <v-tabs-items v-model="tab">
          <v-tab-item eager>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <KeyField
                    name="promotionKey"
                    :label="$i18n.translate('Promotion Key')"
                    hint="Uniquely identifies a promotion in this program"
                    id="promotionKey"
                    v-model="form.promotionKey"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'promotionKey')"
                    @input="$error.clearValidationError(errors, 'promotionKey')"
                  />
                  <PromotionTypeField
                    v-model="selectedPromotionType"
                    :label="$i18n.translate('Promotion Type')"
                    :disabled="!isEditing"
                    show-key
                    :rules="rules.promotionType"
                    class="required"
                  />
                  <v-row class="mb-0 mt-0">
                    <v-col cols="6" class="pb-0 pt-0">
                      <CountryField
                        v-if="selectedPromotionType"
                        v-model="form.country"
                        :label="$i18n.translate('Country')"
                        :rules="rules.country"
                        :disabled="!isEditing || existingClaims"
                        :available="selectedPromotionType.availableCountries"
                        class="required"
                      />
                    </v-col>
                    <v-col cols="6" class="pb-0 pt-0">
                      <v-text-field
                        name="name"
                        :label="$i18n.translate('Fiscal Year')"
                        id="fiscalYear"
                        v-model="form.fiscalYear"
                        :rules="rules.fiscalYear"
                        :disabled="!isEditing"
                        :error-messages="$error.getValidationError(errors, 'fiscalYear')"
                        @input="$error.clearValidationError(errors, 'fiscalYear')"
                        type="number"
                        class="required"
                      />
                    </v-col>
                  </v-row>
                  <v-text-field
                    name="name"
                    :label="$i18n.translate('Promotion Name')"
                    id="name"
                    v-model="form.name"
                    :rules="rules.name"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'name')"
                    @input="$error.clearValidationError(errors, 'name')"
                    class="required"
                  />

                  <v-textarea
                    v-model="form.description"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Description (HTML allowed)')"
                    hint="This description should be in HTML and is rendered on the preview section"
                    name="description"
                    rows="5"
                    :error-messages="$error.getValidationError(errors, 'description')"
                    @input="$error.clearValidationError(errors, 'description')"
                  />
                  <PromotionsField
                    label="Promotions to exclude in duplication checks"
                    v-model="selectedDuplicationCheckPromotions"
                    :showKey="true"
                    :disabled="!isEditing"
                  />
                  <AwardVehicleField
                    v-if="selectedPromotionType"
                    v-model="form.forcedAwardVehicle"
                    :label="`Forced ${$i18n.translate('Award Vehicle')}`"
                    :disabled="!isEditing"
                    :available="availableAwardVehicles"
                    :loading="isLoadingAwardVehicles"
                  />
                  <v-text-field
                    v-model="form.payoutAmountPerProduct"
                    label="Flat Payout Amount Per Product"
                    type="number"
                    min="0"
                    :disabled="!isEditing"
                    clearable
                  />

                  <v-row class="mb-0 mt-0">
                    <v-col cols="6" class="pb-0 pt-0">
                      <TypeField
                        label="Payout Strategy Type"
                        typeName="payoutStrategies"
                        v-model="form.payoutStrategyType"
                        :disabled="!isEditing"
                      />
                    </v-col>
                    <v-col cols="6" class="pb-0 pt-0">
                      <ParticipantField
                        v-if="
                          form &&
                            form.payoutStrategyType &&
                            form.payoutStrategyType.name == 'PAY_TO_PARTICIPANT_SPECIFIC'
                        "
                        :rules="rules.payoutToParticipant"
                        showKey
                        v-model="selectedPayoutToParticipant"
                        label="Payout To Participant"
                        :disabled="!isEditing"
                        class="required"
                      />
                      <OrganizationField
                        v-if="
                          form &&
                            form.payoutStrategyType &&
                            form.payoutStrategyType.name == 'PAY_TO_ORGANIZATION_SPECIFIC'
                        "
                        :rules="rules.payoutToOrganization"
                        showKey
                        v-model="selectedPayoutToOrganization"
                        label="Payout To Organization"
                        :disabled="!isEditing"
                        class="required"
                      />
                    </v-col>
                  </v-row>

                  <v-switch dense label="Publicly Invitable?" v-model="form.publiclyInvitable" :disabled="!isEditing" />

                  <v-row class="mb-0 mt-0">
                    <v-col cols="6" class="pb-0 pt-0">
                      <PromotionAudienceTypeField
                        v-model="form.promotionAudienceType"
                        label="Audience Type"
                        :disabled="!isEditing"
                      />
                    </v-col>
                  </v-row>

                  <v-select
                    :items="[
                      { text: 'Use Award Vehicle Settings', value: null },
                      { text: 'Yes (Override Award Vehicle Settings)', value: true },
                      { text: 'No (Override Award Vehicle Settings)', value: false }
                    ]"
                    v-model="form.taxable"
                    :label="$i18n.translate('Taxable')"
                    :disabled="!isEditing"
                  ></v-select>

                  <v-row class="mb-0 mt-0">
                    <v-col cols="4" class="pb-0 pt-0">
                      <DateTimePickerField
                        v-model="form.publishDate"
                        :disabled="!isEditing"
                        :label="$i18n.translate('Publish Date')"
                        defaultTime="00:00"
                      />
                    </v-col>
                    <v-col cols="4" class="pb-0 pt-0">
                      <DateTimePickerField
                        v-model="form.effectiveDate"
                        :disabled="!isEditing"
                        :label="$i18n.translate('Effective Date')"
                        defaultTime="00:00"
                      />
                    </v-col>
                    <v-col cols="4" class="pb-0 pt-0">
                      <DateTimePickerField
                        v-model="form.expirationDate"
                        :disabled="!isEditing"
                        :label="$i18n.translate('Expiration Date')"
                        defaultTime="23:59:59"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <h2 class="mb-2">Promotion Details</h2>
                  <ul>
                    <li>Based in &quot;{{ form.country ? form.country.name : "" }}&quot;</li>
                    <li>Fiscal Year of &quot;{{ form.fiscalYear }}&quot;</li>
                    <li v-if="form.forcedAwardVehicle">
                      Force awards to use &quot;{{ form.forcedAwardVehicle.name }}&quot;
                    </li>
                    <li>
                      {{ form.payoutStrategyType ? form.payoutStrategyType.description : "" }}
                      <span
                        v-if="
                          form &&
                            form.payoutStrategyType &&
                            form.payoutStrategyType.name == 'PAY_TO_PARTICIPANT_SPECIFIC' &&
                            selectedPayoutToParticipant
                        "
                      >
                        ({{
                          `${selectedPayoutToParticipant.participantKey} - ${selectedPayoutToParticipant.fullName}`
                        }})
                      </span>
                      <span
                        v-if="
                          form &&
                            form.payoutStrategyType &&
                            form.payoutStrategyType.name == 'PAY_TO_ORGANIZATION_SPECIFIC' &&
                            selectedPayoutToOrganization
                        "
                      >
                        ({{ `${selectedPayoutToOrganization.organizationKey} - ${selectedPayoutToOrganization.name}` }})
                      </span>
                    </li>
                    <li>
                      <span v-if="form.taxable == null">Taxable is configured at the Award Vehicle Settings</span>
                      <span v-else-if="form.taxable === true">Is Taxable (Overriding Award Vehicle Settings)</span>
                      <span v-else>Is Not Taxable (Overriding Award Vehicle Settings)</span>
                    </li>
                    <li v-if="form.publiclyInvitable">Is Invite Only</li>
                    <li>
                      <span v-if="form.effectiveDate && form.expirationDate">
                        Is effective between
                        {{ form.effectiveDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
                        to
                        {{ form.expirationDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
                      </span>
                      <span v-else-if="form.effectiveDate">
                        Is effective after
                        {{ form.effectiveDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
                      </span>
                      <span v-else-if="form.expirationDate">
                        Is effective before
                        {{ form.expirationDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
                      </span>
                      <span v-else>
                        Is effective
                      </span>
                    </li>
                    <li>
                      <span v-if="form.publishDate">
                        Published after
                        {{ form.publishDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}
                      </span>
                      <span v-else>
                        Not published
                      </span>
                    </li>
                    <li v-if="form.promotionAudienceType">{{ form.promotionAudienceType.longDescription }}</li>
                  </ul>
                  <h2 class="mt-4 mb-2">Preview of Description</h2>
                  <v-card>
                    <v-card-text v-html="form.description" />
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item eager>
            <PromotionDeadlinesTable
              :promotionId="promotionId"
              :disabled="!isEditing"
              v-model="promotionDeadlines"
              :generateButtonEnabled="form.expirationDate != null && form.effectiveDate != null"
              :deadlinesStart="form.effectiveDate"
              :deadlinesEnd="form.expirationDate"
            />
          </v-tab-item>
          <v-tab-item eager>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <OrganizationTypesField
                    v-model="selectedOrganizationTypes"
                    :label="`Included ${$i18n.translate('Organization Types')}`"
                    :disabled="!isEditing"
                  />
                </v-col>
                <v-col cols="6">
                  <OrganizationGroupsField
                    v-model="selectedOrganizationGroups"
                    :label="`Included ${$i18n.translate('Organization Groups')}`"
                    :disabled="!isEditing"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <ParticipantTypesField
                    v-model="selectedParticipantTypes"
                    :label="`Included ${$i18n.translate('Participant Types')}`"
                    :disabled="!isEditing"
                    multiple
                  />
                </v-col>
                <v-col cols="6">
                  <ParticipantGroupsField
                    v-model="selectedParticipantGroups"
                    :label="`Included ${$i18n.translate('Participant Groups')}`"
                    :disabled="!isEditing"
                    multiple
                  />
                </v-col>
              </v-row>

              <v-switch v-model="requireEligibilityCondition" label="Require Eligibility Condition?" />
              <v-row v-if="selectedPromotionType && selectedPromotionType.rulesEngineBased">
                <v-col>
                  <RuleConditionField
                    label="Eligibility Condition"
                    rows="5"
                    :disabled="!isEditing"
                    :required="requireEligibilityCondition"
                    v-model="participantEligibilityRuleCondition"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2">
                  <v-switch dense label="Invitation Only?" v-model="form.invitationOnly" :disabled="!isEditing" />
                </v-col>
              </v-row>
              <v-row v-if="showPromotionInvitationSetting">
                <v-col cols="12">
                  <v-card variant="outlined">
                    <v-card-title>Promotion Invitation Setting</v-card-title>

                    <PromotionInvitationConfigForm
                      v-model="selectedPromotionInvitationSetting"
                      :disabled="!isEditing"
                    />
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <PromotionParticipantsTable
                    :label="`Include / Exclude Specific ${$i18n.translate('Participants')}`"
                    :promotionId="promotionId"
                    :disabled="!isEditing"
                    v-model="promotionParticipants"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <PromotionOrganizationsTable
                    :label="`Include / Exclude Specific ${$i18n.translate('Organizations')}`"
                    :promotionId="promotionId"
                    :disabled="!isEditing"
                    v-model="promotionOrganizations"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item eager>
            <PromotionProductsTable
              v-if="form.country"
              :promotionId="promotionId"
              :isRuleEngineBased="selectedPromotionType && selectedPromotionType.rulesEngineBased"
              :disabled="!isEditing"
              :hideActions="!isEditing"
              :availableAwardVehicles="availableAwardVehicles"
              v-model="promotionProducts"
              :promotionCountry="form.country"
              :promotionCurrency="form.country.currencyName"
            />
            <span v-else>First select a country for the promotion</span>
          </v-tab-item>
          <v-tab-item
            eager
            v-if="
              selectedPromotionType &&
                selectedPromotionType.inputType &&
                selectedPromotionType.inputType.name == 'CLAIM'
            "
          >
            <v-container>
              <v-row>
                <v-alert v-if="isEditing && existingClaims" type="warning">
                  The Claim Type can't be changed when there are existing claims for the promotion.
                </v-alert>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <ClaimTypeField
                    v-model="selectedClaimType"
                    :label="$i18n.translate('Claim Type')"
                    :disabled="!isEditing || existingClaims"
                    :rules="rules.claimType"
                    class="required"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-switch
                    v-model="form.skipClaimDocumentation"
                    label="Skip Claim Documentation"
                    :disabled="!isEditing"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item eager>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <ContestField v-model="selectedContest" :label="$i18n.translate('Contest')" :disabled="!isEditing" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-if="selectedContest"
                    id="contestEntriesPerSubmission"
                    name="contestEntriesPerSubmission"
                    label="Contest Entries per Submission"
                    v-model="form.contestEntriesPerSubmission"
                    :rules="rules.contestEntriesPerSubmission"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'contestEntriesPerSubmission')"
                    @input="$error.clearValidationError(errors, 'contestEntriesPerSubmission')"
                    type="number"
                    min="1"
                    max="50"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item eager>
            <v-container>
              <v-row v-if="selectedPromotionType">
                <UploadField
                  v-model="promotionUpload"
                  :disabled="!isEditing"
                  v-if="selectedPromotionType"
                  :limitNumberFile="selectedPromotionType.uploadLimit"
                />
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item eager>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <CustomField
                    v-model="form.customFieldValue1"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 1"
                    :customField="selectedProgram.promotionCustomFields[0]"
                  />

                  <CustomField
                    v-model="form.customFieldValue2"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 2"
                    :customField="selectedProgram.promotionCustomFields[1]"
                  />

                  <CustomField
                    v-model="form.customFieldValue3"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 3"
                    :customField="selectedProgram.promotionCustomFields[2]"
                  />

                  <CustomField
                    v-model="form.customFieldValue4"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 4"
                    :customField="selectedProgram.promotionCustomFields[3]"
                  />

                  <CustomField
                    v-model="form.customFieldValue5"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 5"
                    :customField="selectedProgram.promotionCustomFields[4]"
                  />

                  <CustomField
                    v-model="form.customFieldValue6"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 6"
                    :customField="selectedProgram.promotionCustomFields[5]"
                  />

                  <CustomField
                    v-model="form.customFieldValue7"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 7"
                    :customField="selectedProgram.promotionCustomFields[6]"
                  />

                  <CustomField
                    v-model="form.customFieldValue8"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 8"
                    :customField="selectedProgram.promotionCustomFields[7]"
                  />

                  <CustomField
                    v-model="form.customFieldValue9"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 9"
                    :customField="selectedProgram.promotionCustomFields[8]"
                  />

                  <CustomField
                    v-model="form.customFieldValue10"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 10"
                    :customField="selectedProgram.promotionCustomFields[9]"
                  />

                  <CustomField
                    v-model="form.customFieldValue11"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 11"
                    :customField="selectedProgram.promotionCustomFields[10]"
                  />

                  <CustomField
                    v-model="form.customFieldValue12"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 12"
                    :customField="selectedProgram.promotionCustomFields[11]"
                  />

                  <CustomField
                    v-model="form.customFieldValue13"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 13"
                    :customField="selectedProgram.promotionCustomFields[12]"
                  />

                  <CustomField
                    v-model="form.customFieldValue14"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 14"
                    :customField="selectedProgram.promotionCustomFields[13]"
                  />

                  <CustomField
                    v-model="form.customFieldValue15"
                    :disabled="!isEditing"
                    v-if="selectedProgram.promotionCustomFields && selectedProgram.promotionCustomFields.length >= 15"
                    :customField="selectedProgram.promotionCustomFields[14]"
                  />
                </v-col>
                <v-col cols="6">
                  <h2 class="mb-2">Promotion Custom Fields</h2>
                  <ol>
                    <li>
                      Configure each custom field at the Program level.
                    </li>
                  </ol>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item eager v-if="!isNew">
            <ActivityTimeline type="promotion" :id="form.id" />
          </v-tab-item>
          <v-tab-item eager v-if="!isNew">
            <PromotionLimit
              :promotion="form"
              :editing="isEditing"
              :showEditButton="false"
              :showSaveButton="false"
              ref="promotionLimitComponent"
              :eager="true"
            />
          </v-tab-item>
          <v-tab-item eager v-if="!isNew">
            <RuleGroupTable :promotion="form" :editing="isEditing" />
          </v-tab-item>
          <v-tab-item eager v-if="!isNew && form.id">
            <v-row>
              <v-col xl="3" lg="3" md="12" sm="12" xs="12">
                <v-switch
                  dense
                  label="Enable Courses?"
                  hint="By enabling courses, you can prevent payouts until all required courses are completed"
                  persistent-hint
                  v-model="form.coursesEnabled"
                  :disabled="!isEditing"
                />
                <v-switch
                  v-if="form.coursesEnabled"
                  dense
                  label="Require Completed Courses to Generate Payouts?"
                  hint="Payouts would not even be created unless all required courses were completed"
                  persistent-hint
                  v-model="form.coursesRequiredBeforeGeneratingPayout"
                  :disabled="!isEditing"
                />
                <v-switch
                  v-if="form.coursesEnabled"
                  dense
                  label="Require Completed Courses to Pay Payouts?"
                  hint="Keep Payouts in a pending status until all required courses are completed"
                  persistent-hint
                  v-model="form.coursesRequiredBeforePayingPayout"
                  :disabled="!isEditing"
                />
              </v-col>
              <v-col xl="9" lg="9" md="12" sm="12" xs="12">
                <PromotionCoursesTable
                  v-if="promotionCourses"
                  v-model="promotionCourses"
                  :promotion="form"
                  :isEditing="isEditing"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
      <v-dialog v-model="copyConfirm" max-width="500">
        <v-card :loading="isLoading">
          <v-card-title>
            Copy Promotion
          </v-card-title>
          <v-card-text>
            <p>
              By copying this promotion, you will be copying all related pieces of the promotion including products,
              uploads, etc. There are some pieces that will not be copied such as related payouts, activities, etc. To
              copy the promotion, click Copy.
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn @click="onCopyCancel">Cancel</v-btn>
            <v-btn class="primary" @click="onCopy">Copy</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import AwardVehicleField from "@/gapp-components/components/fields/AwardVehicleField.vue";
import ClaimTypeField from "@/gapp-components/components/fields/ClaimTypeField.vue";
import ContestField from "@/gapp-components/components/fields/ContestField.vue";
import CountryField from "@/gapp-components/components/fields/CountryField.vue";
import CustomField from "@/gapp-components/components/fields/CustomField.vue";
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField.vue";
import KeyField from "@/gapp-components/components/fields/KeyField.vue";
import OrganizationField from "@/gapp-components/components/fields/OrganizationField.vue";
import OrganizationGroupsField from "@/gapp-components/components/fields/OrganizationGroupsField.vue";
import OrganizationTypesField from "@/gapp-components/components/fields/OrganizationTypesField.vue";
import ParticipantField from "@/gapp-components/components/fields/ParticipantField.vue";
import ParticipantGroupsField from "@/gapp-components/components/fields/ParticipantGroupsField.vue";
import ParticipantTypesField from "@/gapp-components/components/fields/ParticipantTypesField.vue";
import PromotionAudienceTypeField from "@/gapp-components/components/fields/PromotionAudienceTypeField.vue";
import PromotionTypeField from "@/gapp-components/components/fields/PromotionTypeField.vue";
import PromotionsField from "@/gapp-components/components/fields/PromotionsField.vue";
import RuleConditionField from "@/gapp-components/components/fields/RuleConditionField.vue";
import TypeField from "@/gapp-components/components/fields/TypeField.vue";
import UploadField from "@/gapp-components/components/fields/UploadField.vue";
import PromotionInvitationConfigForm from "@/gapp-components/components/forms/PromotionInvitationConfigForm.vue";
import { fetchPromotionProductsByPromotionId } from "@/gapp-components/components/forms/claim/promotionFetching.js";
import PromotionLimit from "@/gapp-components/components/sony/PromotionLimit.vue";
import ActivityTimeline from "@/gapp-components/components/tables/ActivityTimeline.vue";
import PromotionDeadlinesTable from "@/gapp-components/components/tables/PromotionDeadlinesTable.vue";
import PromotionOrganizationsTable from "@/gapp-components/components/tables/PromotionOrganizationsTable.vue";
import PromotionParticipantsTable from "@/gapp-components/components/tables/PromotionParticipantsTable.vue";
import PromotionProductsTable from "@/gapp-components/components/tables/PromotionProductsTable.vue";
import RuleGroupTable from "@/gapp-components/components/tables/RuleGroupTable.vue";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import PromotionCoursesTable from "../../tables/PromotionCoursesTable.vue";

export default {
  name: "Promotion",
  metaInfo: {
    title: "Promotion"
  },
  components: {
    ApiError,
    KeyField,
    PromotionTypeField,
    CountryField,
    PromotionsField,
    AwardVehicleField,
    ParticipantField,
    OrganizationField,
    DateTimePickerField,
    PromotionDeadlinesTable,
    OrganizationTypesField,
    OrganizationGroupsField,
    ParticipantTypesField,
    ParticipantGroupsField,
    RuleConditionField,
    PromotionInvitationConfigForm,
    PromotionParticipantsTable,
    PromotionOrganizationsTable,
    PromotionProductsTable,
    ClaimTypeField,
    UploadField,
    RuleGroupTable,
    CustomField,
    PromotionLimit,
    TypeField,
    ActivityTimeline,
    ContestField,
    PromotionAudienceTypeField,
    PromotionCoursesTable
  },
  data() {
    return {
      tab: null,
      selectedField: null,
      isValid: true,
      isLoading: false,
      isLoadingAwardVehicles: false,
      isNew: false,
      isEditing: false,
      requireEligibilityCondition: false,
      promotionId: 0,
      errors: {},
      payoutStrategyTypes: [
        {
          text: "Pay To Participant",
          value: { name: "PAY_TO_PARTICIPANT" }
        },
        {
          text: "Pay to Organization",
          value: { name: "PAY_TO_PARENT_ORGANIZATION" }
        },
        {
          text: "Roll Up",
          value: { name: "PAY_ROLL_UP" }
        }
      ],
      form: {
        invitationOnly: false,
        effectiveDate: moment
          .tz([moment().year(), 3, 1, 0, 0, 0], "America/New_York")
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        expirationDate: moment
          .tz([1 + moment().year(), 2, 31, 23, 59, 0], "America/New_York")
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      },
      existingClaims: false,
      availableAwardVehicles: [],
      selectedPromotionType: null,
      selectedClaimType: null,
      selectedParticipantTypes: [],
      selectedParticipantGroups: [],
      selectedOrganizationTypes: [],
      selectedOrganizationGroups: [],
      selectedDuplicationCheckPromotions: [],
      selectedPayoutToParticipant: null,
      selectedPayoutToOrganization: null,
      selectedContest: null,

      rules: {
        payoutToParticipant: [v => (!!v && v.id > 0) || "A payout assignment is required."],
        payoutToOrganization: [v => (!!v && v.id > 0) || "A payout assignment is required."],
        promotionType: [v => !!v || "Promotion Type is required"],
        promotionKey: [v => !!v || "Key is required"],
        name: [v => !!v || "Name is required"],
        country: [v => !!v || "Country is required"],

        effectiveDate: [v => !!v || "Start Date is required"],
        expirationDate: [v => !!v || "End Date is required"],
        publishDate: [v => !!v || "Publish Date is required"],

        claimType: [v => !!v || "Claim Type is required"],
        forcedAwardVehicle: [v => !!v || "Default Award Vehicle is required"],
        fiscalYear: [v => (v > 2000 && v < 3000) || "Fiscal Year must be valid"],
        contestEntriesPerSubmission: [v => (v >= 0.1 && v <= 50) || "Value must be between 1 and 50"]
      },

      promotionProductHeaders: [
        { text: "Model", value: "product.productKey" },
        { text: "Name", value: "product.name" },
        {
          text: "Claiming Min/Max?",
          value: "maximumClaimableUnits",
          align: "center"
        },
        { text: "Payout Cap?", value: "maximumPayoutAmount", align: "right" },
        { text: "Payouts", value: "promotionProductPayouts" },
        { text: "Actions", value: "actions", sortable: false }
      ],

      promotionCourses: {
        existing: [],
        deleted: []
      },
      promotionDeadlines: {
        existing: [],
        deleted: []
      },
      promotionProducts: {
        existing: [],
        deleted: []
      },
      promotionParticipants: {
        existing: [],
        deleted: []
      },
      promotionOrganizations: {
        existing: [],
        deleted: []
      },
      promotionUpload: {
        existing: [],
        deleted: []
      },

      newDeadlinesList: [],
      selectedPromotionInvitationSetting: {
        approvalsNeeded: 0,
        redeemable: false,
        linkToInvitation: null,
        defaultParticipantType: null,
        defaultParticipantStatus: null,

        participantRequesters: {
          existing: [],
          deleted: []
        },
        participantApprovers: {
          existing: [],
          deleted: []
        },
        participantTypesManagement: [],

        emailTemplate: null,
        approverEmailTemplate: null,
        rejectedEmailTemplate: null,
        redeemedEmailTemplate: null
      },

      participantEligibilityRuleCondition: { spelExpression: "" },

      copyConfirm: false,
      copyConfirmItem: null
    };
  },
  watch: {
    async selectedPromotionType(newVal) {
      if (newVal && newVal.id) {
        this.isLoadingAwardVehicles = true;
        try {
          const { data } = await this.$api.get(`/api/promotionTypes/${newVal.id}/availableAwardVehicles`);
          this.availableAwardVehicles = data._embedded?.awardVehicles || [];
        } catch (error) {
          console.error("Error fetching available award vehicles:", error);
          this.availableAwardVehicles = [];
        } finally {
          this.isLoadingAwardVehicles = false;
        }
      } else {
        this.availableAwardVehicles = [];
      }
    }
  },
  methods: {
    onCopyConfirm() {
      this.copyConfirm = true;
    },
    onCopyCancel() {
      this.copyConfirm = false;
    },
    onCopy() {
      this.isLoading = true;
      this.errors = {};
      return this.$api
        .post(`/api/promotions/${this.$route.params.id}/copy`, {})
        .then(({ data }) => {
          this.$router.push({
            name: "promotion",
            params: { id: data.id }
          });
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.onCopyCancel();
          this.isLoading = false;
        });
    },
    async onSubmit() {
      this.isLoading = true;
      this.errors = {};

      let promotion = Object.assign({}, this.form);
      let promotionLimitComponentRef = this.$refs.promotionLimitComponent;

      // If promotionAudienceType is not defined, use the default value { name: "CLIENT_PORTAL" }
      promotion.promotionAudienceType = promotion.promotionAudienceType
        ? promotion.promotionAudienceType
        : { name: "CLIENT_PORTAL" };

      // set promotion deadlines
      if (this.promotionDeadlines.existing) {
        promotion.promotionDeadlines = this.promotionDeadlines.existing;
      }

      // set currency, based on country
      if (promotion.country) {
        promotion.currency = { name: promotion.country.currencyName };
        promotion.country = { name: promotion.country.name };
      }

      // set upload
      if (this.promotionUpload.existing) {
        promotion.uploads = this.promotionUpload.existing;
      }

      //set claim type
      if (this.selectedClaimType) {
        promotion.claimType = this.selectedClaimType;
      }
      //set promotion type
      if (this.selectedPromotionType) {
        promotion.promotionType = this.selectedPromotionType;
      }
      // set selected participant Type
      if (this.selectedParticipantTypes) {
        promotion.participantTypes = this.selectedParticipantTypes;
      }
      // set selected organization groups
      if (this.selectedParticipantGroups) {
        promotion.participantGroups = this.selectedParticipantGroups;
      }
      // set selected organization types
      if (this.selectedOrganizationTypes) {
        promotion.organizationTypes = this.selectedOrganizationTypes;
      }
      // set selected organization groups
      if (this.selectedOrganizationGroups) {
        promotion.organizationGroups = this.selectedOrganizationGroups;
      }

      // set selected duplication check promotions
      if (this.selectedDuplicationCheckPromotions) {
        promotion.duplicationCheckPromotions = this.selectedDuplicationCheckPromotions;
      }

      // set selected forced award vehicle
      if (this.form.forcedAwardVehicle) {
        promotion.forcedAwardVehicle = this.form.forcedAwardVehicle;
      }
      if (!this.isNew && this.form.forcedAwardVehicle == undefined) {
        promotion.forcedAwardVehicle = undefined;
      }

      // set payout to participant
      if (this.selectedPayoutToParticipant) {
        promotion.payoutToParticipant = { id: this.selectedPayoutToParticipant.id };
      }

      // set payout to participant
      if (this.selectedPayoutToOrganization) {
        promotion.payoutToOrganization = { id: this.selectedPayoutToOrganization.id };
      }

      // Set Promotion Invitation link - linkToInvitation
      if (this.selectedPromotionInvitationSetting.linkToInvitation) {
        promotion.promotionInvitationUrl = this.selectedPromotionInvitationSetting.linkToInvitation;
      }
      // Set Promotion Invitation - approvalsNeeded
      if (this.selectedPromotionInvitationSetting.approvalsNeeded) {
        promotion.promotionInvitationApprovalsNeeded = this.selectedPromotionInvitationSetting.approvalsNeeded;
      }

      // Set Promotion Invitation - Redeemable
      promotion.promotionInvitationRedeemable = this.selectedPromotionInvitationSetting.promotionInvitationRedeemable;

      //  Set Promotion Invitation - default participant type
      if (this.selectedPromotionInvitationSetting.defaultParticipantType) {
        promotion.invitationOnlyDefaultParticipantType = {
          id: this.selectedPromotionInvitationSetting.defaultParticipantType.id
        };
      }
      //  Set Promotion Invitation - default participant type
      if (this.selectedPromotionInvitationSetting.defaultParticipantStatus) {
        promotion.invitationOnlyDefaultParticipantStatus = {
          name: this.selectedPromotionInvitationSetting.defaultParticipantStatus.name
        };
      }

      // Set Promotion Invitation - requester participants
      if (this.selectedPromotionInvitationSetting.participantRequesters) {
        promotion.promotionInvitationParticipantRequesters = this.selectedPromotionInvitationSetting.participantRequesters.existing.map(
          p => {
            return { id: p.id };
          }
        );
      }

      // Set Promotion Invitation - approval participants
      if (this.selectedPromotionInvitationSetting.participantApprovers) {
        promotion.promotionInvitationParticipantApprovers = this.selectedPromotionInvitationSetting.participantApprovers.existing.map(
          p => {
            return { id: p.id };
          }
        );
      }

      // Set Promotion Invitation - Participant Type Management
      if (this.selectedPromotionInvitationSetting.participantTypesManagement) {
        promotion.promotionInvitationParticipantTypesManagement = this.selectedPromotionInvitationSetting.participantTypesManagement.map(
          p => {
            return { id: p.id };
          }
        );
      }

      // Set Promotion Invitation Email template to send invitation when is approved
      if (this.selectedPromotionInvitationSetting.emailTemplate) {
        promotion.promotionInvitationEmailTemplate = {
          id: this.selectedPromotionInvitationSetting.emailTemplate.id
        };
      }

      // Set Approver Email template
      if (this.selectedPromotionInvitationSetting.approverEmailTemplate) {
        promotion.promotionInvitationApproverEmailTemplate = {
          id: this.selectedPromotionInvitationSetting.approverEmailTemplate.id
        };
      }

      // Set Rejected Email Template
      if (this.selectedPromotionInvitationSetting.rejectedEmailTemplate) {
        promotion.promotionInvitationRejectedEmailTemplate = {
          id: this.selectedPromotionInvitationSetting.rejectedEmailTemplate.id
        };
      }

      // Set Redeemed Email Template
      if (this.selectedPromotionInvitationSetting.redeemedEmailTemplate) {
        promotion.promotionInvitationRedeemedEmailTemplate = {
          id: this.selectedPromotionInvitationSetting.redeemedEmailTemplate.id
        };
      }

      // Set rule condition
      if (this.participantEligibilityRuleCondition && this.participantEligibilityRuleCondition.spelExpression) {
        promotion.participantEligibilityRuleCondition = this.participantEligibilityRuleCondition;
      }

      //  Set Contest
      if (this.selectedContest) {
        promotion.contest = { id: this.selectedContest.id };
      } else {
        promotion.contest = undefined;
      }

      let postForm = {};

      if (this.promotionProducts) {
        let promotionProducts = [];
        let promotionProductGroups = [];
        for (let promotionProduct of this.promotionProducts.existing) {
          if (promotionProduct.isGroup) {
            promotionProductGroups.push(promotionProduct);
          } else {
            promotionProducts.push(promotionProduct);
          }
        }

        postForm.promotionProducts = promotionProducts;
        postForm.promotionProductGroups = promotionProductGroups;
      }

      // set promotionParticipants
      if (this.promotionParticipants.existing) {
        postForm.promotionParticipants = this.promotionParticipants.existing;
      }

      // set promotion organizations
      if (this.promotionOrganizations.existing) {
        postForm.promotionOrganizations = this.promotionOrganizations.existing;
      }

      // set promotion courses
      if (this.promotionCourses.existing) {
        postForm.promotionCourses = this.promotionCourses.existing.map(pc => {
          // Remove promotion object ... this is populated later
          delete pc.promotion;
          return pc;
        });
      }

      postForm.promotion = promotion;

      let serviceCall = null;
      if (this.isNew) {
        serviceCall = this.$api.post("/api/promotions/submit", postForm);
      } else {
        serviceCall = this.$api.post(`/api/promotions/${this.$route.params.id}/update`, postForm);
      }

      serviceCall
        .then(async ({ data }) => {
          this.form = data;
          if (promotionLimitComponentRef) await promotionLimitComponentRef.onSubmit();
          this.deletePromotionDocument().finally(() => {
            this.isLoading = false;
            this.toggleEditing(false);
            this.isEditing = false;
            if (this.isNew) {
              this.$router.push({
                name: "promotion",
                params: { id: data.id }
              });
            } else {
              this.fetchData();
            }
          });
          this.breadcrumb();
        })
        .catch(error => {
          console.error(error);
          this.isLoading = false;
          this.isEditing = true;
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    deletePromotionDocument() {
      if (this.promotionUpload.deleted) {
        let promiseArray = [];
        this.promotionUpload.deleted.forEach(document => {
          promiseArray.push(this.$api.delete(`/api/uploads/remove/${document.id}`));
        });
        return Promise.all(promiseArray);
      } else {
        return Promise.resolve();
      }
    },
    onEdit() {
      this.toggleEditing(!this.isEditing);
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    async fetchData() {
      this.isLoading = true;
      this.errors = {};
      if (!this.isNew) {
        try {
          const { data } = await this.$api.get(`/api/promotions/${this.$route.params.id}`);
          this.form = data;
          this.selectedPromotionInvitationSetting.linkToInvitation = data.promotionInvitationUrl;
          this.selectedPromotionInvitationSetting.approvalsNeeded = data.promotionInvitationApprovalsNeeded;
          this.selectedPromotionInvitationSetting.promotionInvitationRedeemable = data.promotionInvitationRedeemable;
          this.selectedPromotionInvitationSetting.defaultParticipantStatus =
            data.invitationOnlyDefaultParticipantStatus;
          return Promise.all([
            this.$api
              .getRelatedObject("promotionType", data)
              .then(({ data }) => {
                this.selectedPromotionType = data;
                this.$api
                  .getRelatedObject("availableAwardVehicles", this.form)
                  .then(({ data }) => {
                    this.availableAwardVehicles = data._embedded?.awardVehicles || [];
                  })
                  .catch(() => {
                    console.log("No available award vehicles found");
                  });
              })
              .catch(() => {
                console.log("No promotion type found");
              }),
            this.$api
              .getRelatedObject("forcedAwardVehicle", data)
              .then(({ data }) => {
                this.form.forcedAwardVehicle = data;
              })
              .catch(() => {
                console.log("No forced award vehicle found");
              }),
            this.$api
              .getRelatedObject("claimType", data)
              .then(({ data }) => {
                this.selectedClaimType = data;
              })
              .catch(() => {
                console.log("No claim type found");
              }),
            this.$api
              .getRelatedObject("participantTypes", data)
              .then(({ data }) => {
                this.selectedParticipantTypes = data._embedded?.participantTypes;
              })
              .catch(() => {
                console.log("No participant types found");
              }),
            this.$api
              .getRelatedObject("participantGroups", data)
              .then(({ data }) => {
                this.selectedParticipantGroups = data._embedded?.participantGroups;
              })
              .catch(() => {
                console.log("No participant groups found");
              }),
            this.$api
              .getRelatedObject("organizationTypes", data)
              .then(({ data }) => {
                this.selectedOrganizationTypes = data._embedded?.organizationTypes;
              })
              .catch(() => {
                console.log("No organization types found");
              }),
            this.$api
              .getRelatedObject("organizationGroups", data)
              .then(({ data }) => {
                this.$set(this, "selectedOrganizationGroups", data._embedded?.organizationGroups);
              })
              .catch(() => {
                console.log("No organization groups found");
              }),
            this.$api
              .getRelatedObject("duplicationCheckPromotions", data)
              .then(({ data }) => {
                this.selectedDuplicationCheckPromotions = data._embedded?.promotions;
              })
              .catch(() => {
                console.log("No duplication check promotions found");
              }),
            this.$api
              .getRelatedObject("uploads", data)
              .then(({ data }) => {
                this.promotionUpload.existing = data._embedded?.uploads;
                this.promotionUpload.deleted = [];
              })
              .catch(() => {
                console.log("No uploads found");
              }),
            this.$api
              .getRelatedObject("participantEligibilityRuleCondition", data)
              .then(({ data }) => {
                this.participantEligibilityRuleCondition = data;
              })
              .catch(() => {
                console.log("No participant eligibility rule condition");
              }),
            await fetchPromotionProductsByPromotionId(data.id)
              .then(promotionProducts => {
                this.$set(this.promotionProducts, "deleted", []);
                this.$set(this.promotionProducts, "existing", promotionProducts || []);
                return this.$api
                  .post("/api/promotionProductGroups/search?size=500&page=0&nestedSort=product.productKey,ASC", {
                    promotion: { id: this.form.id }
                  })
                  .then(({ data }) => {
                    data.content.forEach(item => {
                      item.isGroup = true;
                      this.promotionProducts.existing.push(item);
                    });
                  })
                  .catch(() => {
                    console.log("No promotion product groups found");
                  });
              })
              .catch(() => {
                console.log("No promotion products found");
              }),
            this.$api
              .post("/api/promotionCourses/search?size=100", {
                promotion: { id: data.id }
              })
              .then(({ data }) => {
                this.promotionCourses.existing = data.content;
              })
              .catch(() => {
                console.log("No promotion courses found");
              }),
            this.$api
              .post("/api/promotionDeadlines/search?size=100", {
                promotion: { id: data.id }
              })
              .then(({ data }) => {
                this.promotionDeadlines.existing = data;
              })
              .catch(() => {
                console.log("No promotion deadlines found");
              }),
            this.$api
              .getRelatedObject("invitationOnlyDefaultParticipantType", data)
              .then(({ data }) => {
                this.selectedPromotionInvitationSetting.defaultParticipantType = data;
              })
              .catch(() => {
                console.log("No Invitation Only Default Participant Type found");
              }),
            this.$api
              .getRelatedObject("promotionInvitationParticipantRequesters", data)
              .then(({ data }) => {
                this.selectedPromotionInvitationSetting.participantRequesters.existing = data._embedded?.participants;
              })
              .catch(() => {
                console.log("No promotion invitation participant requesters found");
              }),
            this.$api
              .getRelatedObject("promotionInvitationParticipantApprovers", data)
              .then(({ data }) => {
                this.selectedPromotionInvitationSetting.participantApprovers.existing = data._embedded?.participants;
              })
              .catch(() => {
                console.log("No promotion invitation participant approvers found");
              }),
            this.$api
              .getRelatedObject("promotionInvitationParticipantTypesManagement", data)
              .then(({ data }) => {
                this.selectedPromotionInvitationSetting.participantTypesManagement = data._embedded?.participantTypes;
              })
              .catch(() => {
                console.log("No promotion invitation participant types management found");
              }),
            this.$api
              .getRelatedObject("promotionInvitationEmailTemplate", data)
              .then(({ data }) => {
                this.selectedPromotionInvitationSetting.emailTemplate = data;
              })
              .catch(() => {
                console.log("No Promotion Invitation Email Template found");
              }),
            this.$api
              .getRelatedObject("promotionInvitationApproverEmailTemplate", data)
              .then(({ data }) => {
                this.selectedPromotionInvitationSetting.approverEmailTemplate = data;
              })
              .catch(() => {
                console.log("No Promotion Invitation Approver Email Template found");
              }),
            this.$api
              .getRelatedObject("promotionInvitationRejectedEmailTemplate", data)
              .then(({ data }) => {
                this.selectedPromotionInvitationSetting.rejectedEmailTemplate = data;
              })
              .catch(() => {
                console.log("No Promotion Invitation Rejected Email Template found");
              }),
            this.$api
              .getRelatedObject("promotionInvitationRedeemedEmailTemplate", data)
              .then(({ data }) => {
                this.selectedPromotionInvitationSetting.redeemedEmailTemplate = data;
              })
              .catch(() => {
                console.log("No Promotion Invitation Redeemed Email Template found");
              }),
            this.$api
              .getRelatedObject("payoutToParticipant", data)
              .then(({ data }) => {
                this.selectedPayoutToParticipant = data;
              })
              .catch(() => {
                console.log("No Payout To Participant found");
              }),
            this.$api
              .getRelatedObject("payoutToOrganization", data)
              .then(({ data }) => {
                this.selectedPayoutToOrganization = data;
              })
              .catch(() => {
                console.log("No Payout To Organization found");
              }),
            this.$api
              .getRelatedObject("contest", data)
              .then(({ data }) => {
                this.selectedContest = data;
              })
              .catch(() => {
                console.log("No Contest found.");
              }),
            this.checkExistingClaims()
          ]);
        } catch (error) {
          console.error(error);
          this.errors = this.$api.getErrorsFromResponse(error);
        } finally {
          this.isLoading = false;
          this.isEditing = false;
          this.breadcrumb();
        }
      } else {
        this.isLoading = false;
      }
    },
    toggleEditing(editing) {
      this.isEditing = editing;
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Promotions"),
            to: { name: "promotions" },
            exact: true
          },
          {
            text: `${this.$i18n.translate("New")} ${this.$i18n.translate("Promotion")}`
          }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Promotions"),
            to: { name: "promotions" },
            exact: true
          },
          { text: this.form.promotionKey }
        ]);
      }
    },

    setDefaultDates(promotionDeadlines, year) {
      this.defaultDeadlines = [];
      this.defaultBeginDates = [];
      this.defaultEndDates = [];
      this.form.promotionDeadlines = promotionDeadlines;
      if (promotionDeadlines == 1) {
        this.defaultBeginDates.push(moment(this.form.effectiveDate));
        this.defaultEndDates.push(moment(this.form.expirationDate));
        this.defaultDeadlines.push(moment([year + 1, 4, 15]));
      } else if (promotionDeadlines == 2) {
        this.defaultBeginDates.push(moment([year, 3, 1]));
        this.defaultBeginDates.push(moment([year, 9, 1]));
        this.defaultBeginDates.push(moment([year + 1, 3, 1]));
        this.defaultBeginDates.push(moment([year + 1, 9, 1]));

        this.defaultEndDates.push(moment([year, 8, 30]));
        this.defaultEndDates.push(moment([year + 1, 2, 31]));
        this.defaultEndDates.push(moment([year + 1, 8, 30]));
        this.defaultEndDates.push(moment([year + 2, 2, 31]));

        this.defaultDeadlines.push(moment([year, 10, 15]));
        this.defaultDeadlines.push(moment([year + 1, 4, 15]));
        this.defaultDeadlines.push(moment([year + 1, 10, 15]));
        this.defaultDeadlines.push(moment([year + 2, 4, 15]));
      } else if (promotionDeadlines == 4) {
        this.defaultBeginDates.push(moment([year, 3, 1]));
        this.defaultBeginDates.push(moment([year, 6, 1]));
        this.defaultBeginDates.push(moment([year, 9, 1]));
        this.defaultBeginDates.push(moment([year + 1, 0, 1]));
        this.defaultBeginDates.push(moment([year + 1, 3, 1]));
        this.defaultBeginDates.push(moment([year + 1, 6, 1]));
        this.defaultBeginDates.push(moment([year + 1, 9, 1]));
        this.defaultBeginDates.push(moment([year + 2, 0, 1]));

        this.defaultEndDates.push(moment([year, 5, 30]));
        this.defaultEndDates.push(moment([year, 8, 30]));
        this.defaultEndDates.push(moment([year, 11, 31]));
        this.defaultEndDates.push(moment([year + 1, 2, 31]));
        this.defaultEndDates.push(moment([year + 1, 5, 30]));
        this.defaultEndDates.push(moment([year + 1, 8, 30]));
        this.defaultEndDates.push(moment([year + 1, 11, 31]));
        this.defaultEndDates.push(moment([year + 2, 2, 31]));

        this.defaultDeadlines.push(moment([year, 7, 15]));
        this.defaultDeadlines.push(moment([year, 10, 15]));
        this.defaultDeadlines.push(moment([year + 1, 1, 15]));
        this.defaultDeadlines.push(moment([year + 1, 4, 15]));
        this.defaultDeadlines.push(moment([year + 1, 7, 15]));
        this.defaultDeadlines.push(moment([year + 1, 10, 15]));
        this.defaultDeadlines.push(moment([year + 2, 1, 15]));
        this.defaultDeadlines.push(moment([year + 2, 4, 15]));
      }
    },
    computePromotionDeadlines() {
      let effDate = moment(this.form.effectiveDate);
      let expDate = moment(this.form.expirationDate);
      let beginDate = effDate.clone();
      let endDate = beginDate.clone();
      let index = 0;
      let elemCounter = 1;

      // Move existing to deleted
      if (this.promotionDeadlines.existing && this.promotionDeadlines.existing.length > 0) {
        this.promotionDeadlines.existing.forEach(item => {
          this.promotionDeadlines.deleted = this.promotionDeadlines.deleted.concat(item);
        });
      }

      this.promotionDeadlines.existing = [];

      // Find the first date when it should start

      if (this.form.promotionDeadlines != 1) {
        while (this.defaultBeginDates[index + 1].isBefore(beginDate)) {
          index++;
        }
      }

      do {
        beginDate = moment(this.defaultBeginDates[index]);
        endDate = moment(this.defaultEndDates[index]);

        if (this.form.promotionDeadlines != 4 && this.form.promotionDeadlines != 2) {
          endDate = expDate.clone();
        }

        if (endDate.isAfter(expDate)) {
          endDate = expDate.clone();
        }
        let name = "";
        if (this.form.promotionDeadlines == 2) {
          name = `Half ${elemCounter}`;
        } else if (this.form.promotionDeadlines == 4) {
          name = `Quarter ${elemCounter}`;
        } else {
          name = "Single";
        }

        elemCounter++;

        let submissionDeadlineDate;

        if (this.form.submissionDeadlineDays) {
          submissionDeadlineDate = endDate.clone().add(this.form.submissionDeadlineDays, "days");
        } else {
          submissionDeadlineDate = this.defaultDeadlines[index].clone();
        }

        this.promotionDeadlines.existing.push({
          name: name,
          effectiveDate: beginDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          expirationDate: endDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          submissionDeadlineDate: submissionDeadlineDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        });
        beginDate = endDate.clone();
        index++;
      } while (endDate.isBefore(expDate) && index < this.defaultBeginDates.length);
    },
    checkExistingClaims() {
      return this.$api
        .post("/api/claims/search", { promotion: { id: this.form.id } })
        .then(({ data }) => {
          if (data.totalElements > 0) {
            this.existingClaims = true;
          }
        })
        .catch(error => {
          console.error(error);
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    onPromotionInvitationChange() {
      this.onEdit();
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    showPromotionInvitationSetting() {
      return this.form.invitationOnly;
    },
    hasRequiredDetailsFields() {
      return this.selectedPromotionType && this.form.country && this.form.fiscalYear && this.form.name;
    }
  },
  mounted() {
    this.promotionId = parseInt(this.$route.params.id);
    if (this.$route.params.id == 0) {
      this.isNew = true;
      this.isEditing = true;
      this.toggleEditing(true);
      this.breadcrumb();
    }
    this.fetchData();
  }
};
</script>

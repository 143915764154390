<template>
  <v-container>
    <v-row>
      <v-col :cols="disabled ? 12 : 10" class="pa-0">
        <v-textarea
          name="spelExpression"
          :label="label"
          id="spelExpression"
          v-model="ruleCondition.spelExpression"
          @input="onInput"
          @change="onChange"
          v-bind="$attrs"
          :disabled="disabled"
          :rules="required ? rules.spelExpression : []"
          :class="required ? 'required' : ''"
          ref="textarea"
        ></v-textarea>
      </v-col>
      <v-col v-if="!disabled" cols="2">
        <v-menu v-model="fieldsMenu" v-if="!disabled" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on: onMenu }">
            <v-btn class="mb-2" v-on="{ ...onMenu }">
              Fields
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(category, categoryName) in metadata" :key="categoryName">
              <v-select
                v-model="selectedMetadata[categoryName]"
                :items="category"
                :label="categoryName"
                :item-text="item => `${item.name} (${item.type})`"
                item-value="property"
                @change="updateRuleExpression(category, categoryName, $event)"
                clearable
              ></v-select>
            </v-list-item>
          </v-list>
        </v-menu>
        <br />
        <v-btn v-if="!disabled" class="mb-2" @click="showAddCompareDialog = true"> Compare</v-btn>
        <br />
        <v-dialog v-model="showAddCompareDialog" max-width="500">
          <v-card>
            <v-card-title>Compare</v-card-title>
            <v-card-text>
              <v-container>
                <v-select
                  :items="groupedItems"
                  :item-text="item => `${item.headerText ? item.headerText + ' > ' : ''} ${item.name} (${item.type})`"
                  label="Select"
                  return-object
                  group="group"
                  v-model="selectedField"
                ></v-select>
                <div v-if="selectedField">
                  <div v-if="selectedField.type == 'STRING'">
                    <v-select
                      label="Condition"
                      v-model="currentComparison.condition"
                      :items="[
                        { text: 'Equals (Exact case)', value: '==' },
                        { text: 'Equals (Ignore case)', value: 'equalsIgnoreCase' },
                        { text: 'Contains', value: 'contains' },
                        { text: 'Starts With', value: 'startsWith' },
                        { text: 'Ends With', value: 'endsWith' }
                      ]"
                    ></v-select>
                    <v-text-field label="Value" v-model="currentComparison.value"></v-text-field>
                  </div>

                  <div v-else-if="selectedField.type == 'BOOLEAN'">
                    <v-select
                      label="Condition"
                      v-model="currentComparison.condition"
                      :items="[
                        { text: 'Equals', value: '==' },
                        { text: 'Not Equals', value: '!=' }
                      ]"
                    ></v-select>
                    <v-select label="Value" v-model="currentComparison.value" :items="['True', 'False']"></v-select>
                  </div>

                  <div v-else-if="selectedField.type == 'DATE'">
                    <v-select
                      label="Condition"
                      v-model="currentComparison.condition"
                      :items="[
                        { text: 'Greater', value: '>' },
                        { text: 'Greater or Equal', value: '>=' },
                        { text: 'Lower', value: '<' },
                        { text: 'Lower or Equal', value: '<=' },
                        { text: 'Equals', value: '==' },
                        { text: 'Between', value: 'BETWEEN' }
                      ]"
                    ></v-select>
                    <DateTimePickerField
                      :label="
                        'Date' +
                          (currentComparison.condition && currentComparison.condition == 'BETWEEN' ? ' From' : '')
                      "
                      onlyDate
                      format="MM/DD/YYYY"
                      returnFormat="YYYY-MM-DD"
                      v-model="currentComparison.value"
                    ></DateTimePickerField>
                    <DateTimePickerField
                      v-if="currentComparison.condition && currentComparison.condition == 'BETWEEN'"
                      label="Date To"
                      onlyDate
                      format="MM/DD/YYYY"
                      returnFormat="YYYY-MM-DD"
                      v-model="currentComparison.value2"
                    ></DateTimePickerField>
                  </div>

                  <div v-else-if="selectedField.type == 'DECIMAL'">
                    <v-select
                      label="Condition"
                      v-model="currentComparison.condition"
                      :items="[
                        { text: 'Greater', value: '>' },
                        { text: 'Greater or Equal', value: '>=' },
                        { text: 'Lower', value: '<' },
                        { text: 'Lower or Equal', value: '<=' },
                        { text: 'Equals', value: '==' }
                      ]"
                    ></v-select>
                    <v-text-field label="Value" type="number" v-model="currentComparison.value"></v-text-field>
                  </div>

                  <div v-else-if="selectedField.type == 'INTEGER'">
                    <v-select
                      label="Condition"
                      v-model="currentComparison.condition"
                      :items="[
                        { text: 'Greater', value: '>' },
                        { text: 'Greater or Equal', value: '>=' },
                        { text: 'Lower', value: '<' },
                        { text: 'Lower or Equal', value: '<=' },
                        { text: 'Equals', value: '==' }
                      ]"
                    ></v-select>
                    <v-text-field label="Value" type="number" step="1" v-model="currentComparison.value"></v-text-field>
                  </div>
                </div>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer><v-btn @click="showAddCompareDialog = false">Cancel</v-btn
              ><v-btn color="primary" @click="generateExpression">Insert</v-btn>
            </v-card-actions></v-card
          ></v-dialog
        >
        <v-menu v-model="functionsMenu" v-if="!disabled" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on: onMenu }">
            <v-btn class="mb-2" v-on="{ ...onMenu }">
              Functions
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-select
                :items="ruleFormulas"
                label="User Defined"
                item-text="name"
                v-model="selectedRuleFormula"
                @change="updateRuleExpressionWithRuleFormula"
                clearable
              ></v-select>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateTimePickerField from "./DateTimePickerField.vue";
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  components: { DateTimePickerField },
  name: "RuleCondition",
  props: {
    value: Object,
    label: { type: String, default: "Expression" },
    disabled: Boolean,
    required: { type: Boolean, default: false }
  },
  data: () => ({
    ruleCondition: { spelExpression: "" },

    fieldsMenu: false,
    functionsMenu: false,

    valid: false,
    loading: false,
    showAddCompareDialog: false,

    rules: {
      spelExpression: [v => !!v || "Expression is required"]
    },

    selectedRuleFormula: null,
    selectedMetadata: {},
    metadata: {},
    selectedField: null,
    groupedItems: [],
    currentComparison: {},

    ruleFormulas: []
  }),

  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  },
  mounted() {
    this.getRuleEngineMetadata();
    this.ruleCondition = this.value;
  },
  watch: {
    value(v) {
      this.ruleCondition = v;
      this.$emit("input", this.ruleCondition);
    }
  },
  methods: {
    onInput() {
      this.$emit("input", this.ruleCondition);
    },
    onChange() {
      this.$emit("change", this.ruleCondition);
    },
    getRuleEngineMetadata() {
      this.$api.get("/api/ruleEngine/getMetadata").then(({ data }) => {
        this.metadata = data;
        for (let key in data) {
          this.groupedItems.push({ name: key, header: key });

          data[key].forEach(item => {
            item.headerText = key;
            this.groupedItems.push(item);
          });
        }
      });
      this.$api.post("/api/ruleFormulas/search?size=1000&sort=name", {}).then(({ data }) => {
        this.ruleFormulas = data.content;
      });
    },
    generateExpression() {
      let expression = "";
      let selectedField = this.selectedField;
      let currentComparison = this.currentComparison;

      switch (selectedField.type) {
        case "STRING":
          expression = `#${this.mapStringCondition(
            selectedField.property,
            currentComparison.condition,
            currentComparison.value
          )}`;
          break;
        case "BOOLEAN":
          expression = `#compareBoolean(#${selectedField.property}, '${currentComparison.condition}', '${currentComparison.value}')`;
          break;
        case "DATE":
          if (currentComparison.condition == "BETWEEN") {
            expression = `#compareDateBetween(#${selectedField.property}, '${currentComparison.value}', '${currentComparison.value2}')`;
          } else {
            expression = `#compareDate(#${selectedField.property}, '${currentComparison.condition}', '${currentComparison.value}')`;
          }
          break;
        case "DECIMAL":
          expression = `#compareDecimal(#${selectedField.property}, '${currentComparison.condition}', '${currentComparison.value}')`;
          break;
        case "INTEGER":
          expression = `#compareInteger(#${selectedField.property}, '${currentComparison.condition}, '${currentComparison.value}'')`;
          break;
        default:
          expression = "Unsupported";
          break;
      }

      if (this.ruleCondition.spelExpression.length > 0) {
        this.ruleCondition.spelExpression += " ";
      }

      this.ruleCondition.spelExpression += expression;
      this.onChange(this.ruleCondition.spelExpression);
      Vue.set(this, "selectedField", {});
      Vue.set(this.currentComparison, "condition", {});
      Vue.set(this.currentComparison, "value", "");
      this.showAddCompareDialog = false;
    },
    mapStringCondition(property, condition, v) {
      switch (condition) {
        case "==":
          return `${property} == '${v}'`;
        case "contains":
          return `${property}.contains('${v}')`;
        case "startsWith":
          return `${property}.startsWith('${v}')`;
        case "endsWith":
          return `${property}.endsWith('${v}')`;
        case "equalsIgnoreCase":
          return `${property}.equalsIgnoreCase('${v}')`;
        default:
          return `${property} == '${v}'`;
      }
    },
    updateRuleExpression(category, categoryName, selectedValue) {
      if (selectedValue) {
        let textAreaValue =
          this.ruleCondition && this.ruleCondition.spelExpression ? this.ruleCondition.spelExpression : "";
        if (textAreaValue.length > 0) {
          textAreaValue += " ";
        }
        textAreaValue += "#" + selectedValue;
        this.ruleCondition.spelExpression = textAreaValue;
        this.onChange(this.ruleCondition.spelExpression);
        Vue.set(this.selectedMetadata, categoryName, null);
        this.fieldsMenu = false;
      }
    },
    updateRuleExpressionWithRuleFormula() {
      if (this.selectedRuleFormula) {
        let textAreaValue =
          this.ruleCondition && this.ruleCondition.spelExpression ? this.ruleCondition.spelExpression : "";
        if (textAreaValue.length > 0) {
          textAreaValue += " ";
        }
        textAreaValue += "#" + this.selectedRuleFormula + ".get()";
        this.ruleCondition.spelExpression = textAreaValue;
        this.onChange(this.ruleCondition.spelExpression);
        Vue.set(this, "selectedRuleFormula", {});
        this.functionsMenu = false;
      }
    }
  }
};
</script>

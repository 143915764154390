<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    item-value="id"
    :item-text="render"
    clearable
    return-object
  >
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "CourseField",
  props: {
    value: Object,
    status: { type: String, required: false },
    showKey: {
      type: Boolean,
      default: false,
      required: false
    },
    preventIds: Array
  },
  data: () => ({
    items: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    render(item) {
      let response = item.name;
      if (this.showKey) {
        response = item.courseKey + " - " + response;
      }
      return response;
    },
    fetchData() {
      return ApiService.post("/api/courses/search?size=100", { isEffective: this.status }).then(({ data }) => {
        let dbItems = data.content;

        if (this.preventIds && this.preventIds.length > 0) {
          dbItems = dbItems.reduce((acc, cur) => {
            if (this.preventIds.indexOf(cur.id) === -1 || cur.id === this.value?.id) {
              acc.push(cur);
            }
            return acc;
          }, []);
        }

        this.items = dbItems;
      });
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    preventIds: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  }
};
</script>

<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    item-value="name"
    item-text="description"
    clearable
    return-object
  >
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "TypeField",
  props: {
    value: Object,
    typeName: String
  },
  data: () => ({
    items: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    }
  },
  mounted() {
    ApiService.get("/api/types/" + this.typeName).then(({ data }) => {
      this.items = data;
    });
  }
};
</script>

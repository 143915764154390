<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    :loading="loading"
    item-value="id"
    item-text="name"
    clearable
    return-object
  >
  </v-select>
</template>

<script>
import ApiService from "@/gapp-components/services/api.service";

export default {
  name: "ContestField",
  props: {
    value: Object
  },
  data: () => ({
    items: [],
    loading: false
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    }
  },
  mounted() {
    let filters = {};
    filters.isActive = true;

    ApiService.post("/api/contests/search", filters)
      .then(({ data }) => {
        this.loading = false;
        this.items = data.content;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      });
  }
};
</script>

<template>
  <v-data-table :items="items.existing" :headers="headers" :loading="loading">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Promotion Courses</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" v-if="isEditing" width="700">
          <template v-slot:activator="{ on: onDialog, attrs: attrsDialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                <v-btn
                  @click="onNewItem"
                  fab
                  small
                  v-bind="{ ...attrsDialog, ...attrsTooltip }"
                  v-on="{ ...onDialog, ...onTooltip }"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>New Promotion Course</span>
            </v-tooltip>
          </template>
          <v-form ref="form" @submit.prevent="onSaveItem" v-model="valid">
            <v-card>
              <v-card-title>{{
                editedItem && editedItem.id ? "Update Promotion Course" : "New Promotion Course"
              }}</v-card-title>
              <v-card-text>
                <ApiError :errors="errors"></ApiError>
                <v-container v-if="editedItem">
                  <v-row>
                    <v-col cols="12">
                      <CourseField
                        v-if="dialog"
                        v-model="editedItem.course"
                        label="Active Course"
                        showKey
                        :preventIds="existingCourseIds"
                        :rules="rules.course"
                        :error-messages="$error.getValidationError(errors, 'course')"
                        @input="$error.clearValidationError(errors, 'course')"
                        class="required"
                      ></CourseField>
                      <v-text-field
                        v-model="editedItem.overridePassingGrade"
                        label="Override Passing Grade?"
                        type="number"
                        :error-messages="$error.getValidationError(errors, 'overridePassingGrade')"
                        @input="$error.clearValidationError(errors, 'overridePassingGrade')"
                      />
                      <DateTimePickerField
                        v-model="editedItem.effectiveDate"
                        :disabled="!isEditing"
                        :label="$i18n.translate('Effective Date')"
                        defaultTime="00:00"
                      ></DateTimePickerField>
                      <DateTimePickerField
                        v-model="editedItem.expirationDate"
                        :disabled="!isEditing"
                        :label="$i18n.translate('Expiration Date')"
                        defaultTime="23:59"
                      ></DateTimePickerField>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="onCloseItem">{{ $i18n.translate("Cancel") }}</v-btn>
                <v-btn color="blue darken-1" text :disabled="!valid" @click="onSaveItem">{{
                  editedIndex > -1 ? $i18n.translate("Update") : $i18n.translate("Add")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.name="{ item }">
      <router-link :to="{ name: 'course', params: { id: item.course.id } }">
        {{ item.course.name }}
      </router-link>
    </template>
    <template v-slot:item.description="{ item }">
      {{ item.course.description }}
    </template>
    <template v-slot:item.passingGrade="{ item }">
      {{
        item.overridePassingGrade
          ? item.overridePassingGrade
          : item.course.passingGrade
          ? item.course.passingGrade
          : "N/A"
      }}
    </template>
    <template v-slot:item.rankOrder="{ item }">
      {{ item.course.rankOrder }}
    </template>
    <template v-slot:item.effective="{ item }">
      <span v-if="item.dirty || item.effective == null"></span>
      <v-chip color="primary" v-else-if="item.effective">{{ $i18n.translate("Active") }}</v-chip>
      <v-chip color="danger" v-else>{{ $i18n.translate("Inactive") }}</v-chip>
    </template>
    <template v-slot:item.updatedDate="{ item }">
      {{ item.updatedDate | formatDateFromNow }}
    </template>
    <template v-slot:item.actions="{ item }" v-if="isEditing">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onEditItem(item)">mdi-pencil</v-icon>
        </template>
        <span>Edit Course</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onDeleteItem(item)">mdi-delete</v-icon>
        </template>
        <span>Delete Course</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../display/ApiError.vue";
import CourseField from "../fields/CourseField.vue";
import DateTimePickerField from "../fields/DateTimePickerField.vue";

export default {
  components: { ApiError, CourseField, DateTimePickerField },
  name: "PromotionCoursesTable",
  props: {
    promotion: Object,
    value: Object,
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      items: [],
      nameFilter: "",
      headers: [
        {
          value: "name",
          text: "Name",
          sortable: true
        },
        {
          value: "description",
          text: "Description",
          sortable: true
        },
        {
          value: "passingGrade",
          text: "Passing Grade?",
          sortable: true,
          align: "right"
        },
        {
          value: "rankOrder",
          text: "Rank",
          sortable: true,
          align: "right"
        },
        {
          value: "effective",
          text: "Status",
          align: "center"
        },
        {
          value: "updatedDate",
          text: "Last Updated",
          sortable: true
        },
        {
          value: "actions",
          text: "Actions",
          sortable: false
        }
      ],
      rules: { course: [v => !!v || "Course is required"] },
      valid: false,
      errors: {},
      dialog: false,
      editedIndex: null,
      editedItem: null
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.items);
      this.onCloseItem();
    },
    onNewItem() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      this.editedIndex = -1;
      this.editedItem = { awardUnitsValueInCurrency: 1 };
      this.dialog = true;
    },
    onCloseItem() {
      this.dialog = false;
    },
    onEditItem(item) {
      console.log(item);
      this.editedIndex = this.items.existing.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    onDeleteItem(item) {
      let index = this.items.existing.indexOf(item);
      let deletedItems = this.items.existing.splice(index, 1);
      deletedItems = deletedItems.reduce((acc, cur) => {
        if (cur.id) {
          acc.push(cur);
        }
        return acc;
      }, []);
      this.items.deleted = this.items.deleted.concat(deletedItems);
      this.onInput();
    },
    onSaveItem() {
      this.editedItem.dirty = true;
      if (this.editedIndex > -1) {
        Object.assign(this.items.existing[this.editedIndex], this.editedItem);
      } else {
        this.items.existing.push(this.editedItem);
      }

      this.onInput();
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    existingCourseIds() {
      return this.items.existing.map(i => i.course.id);
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        this.items = v;
      }
    }
  }
};
</script>
